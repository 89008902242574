<!-- 个人中心-页面 -->
<template>
  <div class="page-mcenter">
    <div class="my-user">
      <div class="user-icon">
        <img
          src="@/assets/images/my/user-icon.png"
          class="user-img"
          alt=""
          width="127"
          height="127"
        />
      </div>
      <div class="user-Amount">
        <div class="my-userText">
          <span>{{ userData.name }}</span>
          <div class="user_btn cursorPointer" @click="vipListReback">
            <img
              src="@/assets/images/my/vip.png"
              class="vip-img"
              alt=""
              width="150"
              height="150"
            />
            {{
              userData._vipLevel > 0
                ? "vip" + userData._vipLevel
                : $t("userPage.text295")
            }}
          </div>
        </div>
        <div class="my-money">
          <span>{{ $t("userPage.text296") + "：" }}</span>
          <span class="name">{{ userData.totalBalance }}</span>
          <i
            class="refresh-btn refresh-btn-lg"
            :class="{ rotate: rotate }"
            @click="getPlatformBalance(true)"
          ></i>
        </div>
        <div class="my-money">
          <span>{{ $t("userPage.text297") + "：" }}</span>
          <span>{{ reback.rebateAmount }}</span>
          <i
            class="refresh-btn refresh-btn-lg"
            :class="{ rotate: rotate1 }"
            @click="getReback(true)"
          ></i>
          <span
            class="receive-icon"
            :class="disabledGetReturnWater ? '' : 'disabledGet'"
            @click="openRebackVisible"
            >{{ $t("userPage.text169") }}</span
          >
        </div>
      </div>
    </div>
    <div class="my-userTop">
      <div class="my-Withdrawcash">
        <div
          class="Withdrawcash-list"
          :class="curRouter == item.pathName ? 'active' : ''"
          v-for="(item, i) in withdrawcashList"
          :key="'w' + i"
          @click="tolink(item.pathName, i)"
        >
          <span class="icon-bordr">
            <i :class="'Withdrawcash-icon' + (i + 1)"></i>
          </span>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="user-item-list">
      <Menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <Submenu index="1" class="my-left-item">
          <template slot="title">
            <i class="my_icon my_icon1"></i>
            <span slot="title">{{ this.$t("userPage.text307") }}</span>
          </template>
          <MenuItem
            class="my-left-item"
            @click="tolink(item.pathName, i)"
            v-for="(item, i) in amountList"
            :key="i"
          >
            <i class="my_icon" :class="'my_icon' + (i + 1)"></i>
            <span slot="title">{{ item.name }}</span>
          </MenuItem>
        </Submenu>
        <MenuItem
          class="my-left-item"
          @click="tolink(item.pathName, i)"
          v-for="(item, i) in navList"
          :key="i"
        >
          <i class="my_icon" :class="'my_icon' + (i + 3)"></i>
          <span slot="title">{{ item.name }}</span>
          <i class="icon-right el-icon-arrow-right"></i>
        </MenuItem>
      </Menu>
      <div class="profile-sub-cat">
        <div class="profile-sub-list"></div>
      </div>
    </div>
    <div class="loginOut">
      <Button class="btn-logout" @click="loginOut">{{
        $t("homePageLogin.text6")
      }}</Button>
    </div>
    <!-- <div class="my-right">
            <router-view />
        </div> -->
    <!-- 领取返水 弹窗 -->
    <Dialog
      :visible.sync="rebackVisible"
      :title="$t('userPage.text299')"
      center
      width="400px"
    >
      <div class="Dialog-content">
        <div class="amount">￥{{ reback.rebateAmount || "0.00" }}</div>
        <div>
          {{ $t("userPage.text299") + "：" }}{{ reback.verityCount
          }}{{ $t("userPage.text153") }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- 查看详情 -->
        <Button @click="toRebateInfo">{{ $t("userPage.text11") }}</Button>
        <!-- 立即领取 -->
        <Button type="primary" @click="receiveReback">{{
          $t("userPage.text155")
        }}</Button>
      </div>
    </Dialog>
    <!-- vip详情 弹窗 -->
    <Dialog
      :visible.sync="vipListVisible"
      :title="$t('userPage.text299')"
      class="vipList-Dialog"
    >
      <div style="min-height: 380px">
        <!-- <div>{{ vipLevelList }}</div> -->
        <Table
          :data="vipLevelList"
          border
          style="width: 100%"
          header-cell-class-name="vipList-Dialog-header"
          cell-class-name="vipList-Dialog-item"
        >
          <!-- 等级 -->
          <Table-column
            prop="gradeName"
            :label="$t('userPage.text300')"
            align="center"
          >
          </Table-column>
          <!-- 达成条件 -->
          <Table-column :label="$t('userPage.text301')" align="center">
            <template slot-scope="scope">
              <span>{{
                $t("userPage.text66") +
                scope.row.charge +
                "," +
                $t("userPage.text302") +
                scope.row.bet
              }}</span>
            </template>
          </Table-column>
          <!-- 有效投注 -->
          <Table-column :label="$t('userPage.text138')" align="center">
            <template slot-scope="scope">
              <span>{{
                "24h/" + scope.row.withdrawLimit + $t("userPage.text302")
              }}</span>
            </template>
          </Table-column>
        </Table>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { _getNotReceive } from "@/core/api/modules/promo";
import { _loginOut } from "@/core/comp/common";
import { _noticeList } from "@/core/api/modules/system";
import { _jump } from "@/core/utils/utils";
import {
  _getBankList,
  _getPlatformBalance,
  _getReback,
  _receiveReback,
  _getVipList,
} from "@/core/api/modules/user";
import {
  Message,
  Button,
  Menu,
  Submenu,
  MenuItem,
  Dialog,
  Table,
  TableColumn,
} from "element-ui";
export default {
  components: {
    Button,
    Menu,
    Submenu,
    MenuItem,
    Dialog,
    Table,
    TableColumn,
  },
  data() {
    return {
      navList: [
        // { name: this.$t("userPage.text303"), pathName: 'userInfo', },//
        // { name: this.$t("userPage.text307"),pathName: 'bankCard',icon:''},//我的钱包
        { name: this.$t("userPage.text304"), pathName: "gameRecord" }, //游戏记录
        { name: this.$t("userPage.text305"), pathName: "transRecord" }, //游戏记录
        { name: this.$t("userPage.text306"), pathName: "notice" }, //公告
        // { name: this.$t("userPage.text308"), pathName: 'activeRecord', },//活动记录
        // { name: this.$t("userPage.text309"), pathName: 'rebate', },//全民返利
      ],
      withdrawcashList: [
        { name: this.$t("userPage.text310"), pathName: "deposit" }, //存款
        { name: this.$t("userPage.text311"), pathName: "withdraw" }, //取款
        { name: this.$t("userPage.text317"), pathName: "platformBalance" }, //转移\归集
        { name: this.$t("userPage.text312"), pathName: "jackpot" }, //彩金
      ],
      amountList: [
        { name: this.$t("userPage.text303"), pathName: "userInfo" }, //用户信息
        { name: this.$t("userPage.text314"), pathName: "password" }, //密码
        { name: this.$t("userPage.text315"), pathName: "bankCard" }, //银行卡
        // { name: this.$t("userPage.text316"), pathName: 'rebate', },//语言
      ],
      isactive: 0,
      curRouter: "deposit",
      reback: {},
      rotate: false,
      rotate1: false,
      rebackVisible: false,
      vipListVisible: false,
      disabledGetReturnWater: false, //默认不可以领取返水
    };
  },
  computed: {
    ...mapGetters(["userData", "vipLevelList"]),
  },
  watch: {
    $route: {
      handler: function (val) {
        this.curRouter = val.name;
      },
      immediate: true,
    },
  },
  mounted() {
    setTimeout(() => {
      _getVipList(); // 获取vip等级列表
      _getBankList(); // 绑定的银行卡列表
    }, 300);
    setTimeout(() => {
      this.getReback(false); //获取返水信息
      _getNotReceive(); // 是否有彩金需要领取
      _noticeList({}); // 获取未读公告
    }, 1000);
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    _jump,
    tolink(path) {
      this.curRouter = path;
      _jump(path);
    },
    // 退出
    loginOut() {
      _loginOut();
      Message.success(this.$t("homePageLogin.text24"));
      setTimeout(() => {
        this.$router.push({ name: "home" });
      }, 500);
    },
    getPlatformBalance(isRefresh) {
      this.rotate = true;
      _getPlatformBalance().then((res) => {
        if (res && isRefresh) {
          Message.success("刷新成功");
          this.rotate = false;
        }
      });
    },
    toRebateInfo() {
      this.rebackVisible = false;
      _jump("rebateInfo");
    },
    // 获取返水信息
    getReback(isMessage) {
      this.rotate1 = true;
      _getReback()
        .then((res) => {
          if (res && res.code == 0) {
            this.reback = res.data;
            this.reback.rebateAmount = this.reback.rebateAmount
              ? this.reback.rebateAmount.toFixed(2)
              : "0.00";
            this.disabledGetReturnWater = true;
            this.rotate1 = false;
            if (isMessage) Message.success("刷新成功");
          } else {
            this.disabledGetReturnWater = false;
            this.rotate1 = false;
            this.reback.rebateAmount = "0.00";
            Message.error(res.msg);
          }
        })
        .catch(() => {
          this.rotate1 = false;
        });
    },
    openRebackVisible() {
      if (!this.disabledGetReturnWater) return;
      if (this.reback.rebateAmount < this.reback.rebateDown)
        return Message.warning(this.$t("userPage.text313"));
      this.rebackVisible = true;
    },
    // 领取返水
    receiveReback() {
      _receiveReback().then((res) => {
        if (res) {
          this.rebackVisible = false;
          this.getReback(false);
        }
      });
    },
    // 领取返水
    vipListReback() {
      this.vipListVisible = true;
      console.log("---vip", this.vipLevelList);
    },
  },
};
</script>

<style scoped lang="scss">
$imgUrl: "~@/assets/images/my/";
.page-mcenter {
  width: 100%;
  height: auto;
  color: #000;
  // background:url(#{$imgUrl}my_bg.jpg)repeat top center;
  // background-size: cover;
  background: #f7f7f7;
  padding-bottom: 40px;
  .my-user {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #ce06cd;
    height: 240px;
    padding-top: 30px;
    color: #fff;
    .user-icon {
      .user-img {
        margin: 0 auto;
        border-radius: 50%;
        border: none;
      }
    }
    .user-Amount {
      margin-left: 50px;
      .my-userText {
        margin: 5px auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font-size: 16px;
          margin-right: 10px;
        }
        .user_btn {
          text-align: center;
          padding: 0px 8px;
          box-sizing: border-box;
          width: 120px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          background: url(#{$imgUrl}jackpot1_btn.png) no-repeat top center/contain;
          .vip-img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
      }
      .my-money {
        text-align: left;
        display: flex;
        margin: 10px auto;
        align-items: center;
        span:nth-child(2) {
          font-size: 16px;
          font-weight: 700;
          color: #fdf86a;
        }
        .receive-icon {
          width: 65px;
          height: 34px;
          font-size: 14px;
          line-height: 34px;
          text-align: center;
          background: url(#{$imgUrl}btn_balance.png) no-repeat center/contain;
          cursor: pointer !important;
        }
        .rotate {
          animation: refresh 2s linear infinite;
          -moz-animation: refresh 2s linear infinite;
          /* Firefox */
          -webkit-animation: refresh 2s linear infinite;
          /* Safari and Chrome */
          -o-animation: refresh 2s linear infinite;
          /* Opera */
        }
        .disabledGet {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          cursor: inherit;
        }
      }
    }
  }
  .my-userTop {
    font-size: 20px;
    max-width: 890px;
    background: #ffffff;
    border-radius: 10px;
    display: block;
    margin: -65px auto 0;
    .my-Withdrawcash {
      display: flex;
      margin: 0px auto 20px;
      justify-content: space-between;
      align-items: center;
      padding: 27px 0 20px 0;
      .Withdrawcash-list {
        flex: 1;
        cursor: pointer;
        text-align: center;
        &.active {
          color: #ce06cd;
          .icon-bordr {
            border: 1px solid #ce06cd;
          }
        }
        .icon-bordr {
          display: block;
          padding: 10px;
          text-align: center;
          margin: 0px auto 10px;
          border: 1px solid transparent;
          border-radius: 10px;
        }
        @for $i from 1 to 5 {
          .Withdrawcash-icon#{$i} {
            width: 51px;
            height: 40px;
            margin: 0 auto;
            display: block;
            background: url(#{$imgUrl}my_vip#{$i}.png) no-repeat center/contain;
          }
        }
      }
    }
  }
  .loginOut {
    margin-top: 20px;
    .btn-logout {
      display: block;
      width: 362px;
      height: 40px;
      background-color: #ce06cd;
      font-size: 16px;
      font-weight: bold;
      margin: 0 auto;
      border-radius: 10px;
      color: #fff;
    }
  }
  .user-item-list {
    font-size: 20px;
    max-width: 890px;
    background: #ffffff;
    border-radius: 10px;
    display: block;
    margin: 0 auto 25px;
    overflow: hidden;
    .my-left-item {
      margin: 0 auto;
      min-height: 66px;
      padding: 0 20px;
      cursor: pointer;
      display: flex;
      color: #000 !important;
      font-size: 16px;
      font-weight: 700;
      justify-content: flex-start;
      align-items: center;
      background: #ffffff !important;
      border-bottom: 1px solid #dedede;
      &.active,
      &:hover {
        // color: #fff;
        // border-radius: 10px;
        // border: 1px solid #3e3e3e;
        // background-color: #000000;
      }
      i {
        color: #ce06cd !important;
      }
      .my_icon {
        width: 32px;
        height: 32px;
        display: block;
        margin-right: 30px;
      }
      .icon-right {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -7px;
        margin-left: auto;
        font-size: 28px;
        font-weight: 700;
      }
      @for $i from 1 to 8 {
        .my_icon#{$i} {
          background: url(#{$imgUrl}my_icon#{$i}.png) no-repeat center/contain;
        }
      }
    }
    .my-left-item:nth-last-child(1) {
      border-bottom: none;
    }
  }
}
</style>
<style lang="scss">
.user-item-list {
  .el-menu {
    background: transparent !important;
    border: none !important;
  }
  .my-left-item.el-submenu {
    display: block !important;
    .el-menu {
      display: grid;
      gap: 10px 0;
      background: transparent !important;
      grid-template-columns: repeat(3, 1fr);
      &::before {
        display: none;
      }
      .el-menu-item {
        padding: 10px 0 !important;
        text-align: center;
        display: block !important;
        border-bottom: none;
        min-height: 100px;
        i.my_icon {
          margin: 0 auto;
          width: 50px;
          height: 50px;
        }
        @for $i from 1 to 5 {
          .my_icon#{$i} {
            background: url("~@/assets/images/my/userinfo-#{$i}.png")
              no-repeat
              center/contain;
          }
        }
      }
    }
    .el-submenu__title {
      color: #000 !important;
      font-size: 16px;
      font-weight: 700;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 !important;
      background: transparent !important;
      i {
        color: #ce06cd !important;
      }
    }
  }
  .el-submenu__icon-arrow {
    right: 0;
    font-size: 28px;
    font-weight: 700;
  }
}
.vipList-Dialog {
  .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        text-align: left;
        font-size: 24px;
        display: block;
        font-weight: 700;
      }
    }
    .el-dialog__body {
      .el-table {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        &::before {
          background-color: #ce06cd;
        }
        .vipList-Dialog-header {
          border: none;
          color: #fff;
          background: #ce06cd;
          border-bottom: 1px solid #ce06cd;
        }
        .vipList-Dialog-item {
          border-right: 1px solid #ce06cd;
        }
        .vipList-Dialog-item,
        th.el-table__cell.is-leaf {
          border-bottom: 1px solid #ce06cd;
        }
        .vipList-Dialog-item {
          border-right: 1px solid #ce06cd;
        }
      }
      .el-table--border {
        border: 1px solid #ce06cd;
        border-right: none;
        border-bottom: none;
        &::after {
          background-color: #ce06cd;
        }
      }
    }
  }
}
</style>